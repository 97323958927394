@import '../../styles/variables.less';

.common-config-component {
  li p {
    margin: 0;
    padding: 0;
  }
  text-align: center;
  .horizontal-padding {
    padding: @padding-horizontal @padding-vertical;
    .general-section {
      .full-width-image {
        margin-left: -40px;
        margin-right: -40px;
        margin-top: -32px;
        width: auto;
        .header-image {
          height: 250px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .widget-section {
      margin: 20px 0;
      .my-project-component {
        .service-type-text {
          margin: 20px 0px;
          color: @black-color;
        }
        .tile-grid {
          .tile-view {
            border: 1px solid @pick-list-border;
            width: 50%;
            min-height: unset; // after radio group UI changes done, will remove this
            &.grid-card:not(:nth-child(-n + 2)) {
              border-top: none;
            }
            &.grid-card:nth-child(even) {
              border-left: 0px;
            }
            &.grid-card:only-child {
              border-radius: 6px;
            }
            &.card-selected {
              border: 1px solid @primary-color;
            }
            &.card-selected.ant-card-grid-hoverable:hover {
              box-shadow: none;
            }
            &.ant-card-grid-hoverable:focus-visible {
              box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
                0 3px 6px 0 rgba(0, 0, 0, 0.12),
                0 5px 12px 4px rgba(0, 0, 0, 0.09);
              outline: none;
            }
          }
          .list-view {
            width: 100%;
            border: 1px solid @pick-list-border;
            min-height: unset; // after radio group UI changes done, will remove this
            &.grid-card:last-child {
              border-top: 0;
            }
            &.card-selected {
              border: 1px solid @primary-color;
            }
            &.card-selected.ant-card-grid-hoverable:hover {
              box-shadow: none;
            }

            &.ant-card-grid-hoverable:focus-visible {
              box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
                0 3px 6px 0 rgba(0, 0, 0, 0.12),
                0 5px 12px 4px rgba(0, 0, 0, 0.09);
              outline: none;
            }
            &.card-selected.ant-card-grid {
              box-shadow: none;
            }
          }
        }
      }
      .boolean-component {
        margin-top: 35px;
        .tile-grid {
          .tile-view {
            width: 50%;
            border: 1px solid @pick-list-border;

            &.card-selected {
              border: 1px solid @primary-color;
            }
            &.ant-card-grid-hoverable:focus-visible {
              box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
                0 3px 6px 0 rgba(0, 0, 0, 0.12),
                0 5px 12px 4px rgba(0, 0, 0, 0.09);
              outline: none;
            }
          }
        }
      }
      .ant-form-item-label > label {
        font-size: 16px;
        color: @field-color;
        flex-direction: row-reverse;
      }
      .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        margin-bottom: 6px;
      }
      .ant-form-item-label
        > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        color: @text-color-secondary;
        margin: 0px 4px 6px 3px;
      }

      .label-with-tooltip {
        .ant-form-item-label {
          label {
            flex-direction: row;
            align-items: flex-end;
            .ant-form-item-tooltip {
              display: flex;
              order: 1;
              margin-inline-start: 4px;
            }
          }
          > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            display: flex;
            order: 1;
          }
          > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
            .ant-form-item-tooltip {
              margin-inline-start: 0px;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    .footer-section {
      .countdown-text {
        display: flex;
        margin-top: 15px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: @text-color-secondary;
        word-break: break-word;
        .ant-statistic-content {
          font-size: 14px;
          color: @text-color-secondary;
          .ant-statistic-content-value {
            min-width: 64px;
          }
        }
      }
      .my-cart-btn {
        margin: auto;
        margin-top: 16px;
        padding: 8px 0;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        color: @text-primary;
        font-weight: 500;
        .count-badge {
          order: 1;
          margin-right: 8px;
        }
        .anticon {
          margin-left: 6px;
        }
        &:hover {
          background: transparent;
        }
        &:focus {
          background: transparent;
        }
      }

      .my-cart-btn.ant-btn:not(:disabled):focus-visible {
        &.ant-btn > span {
          font-size: 17px;
          font-weight: bold;
        }
      }

      .contact-me-btn {
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        text-decoration: underline;
        white-space: normal;
        word-break: break-word;
        height: unset;
        font-weight: 700;
        .anticon {
          margin-left: 8px;
        }
      }
      .contact-me-btn.ant-btn:not(:disabled):focus-visible {
        font-size: 16px;
        font-weight: bold;
      }

      .back-btn {
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        :nth-child(2) {
          max-width: 700px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .anticon svg {
          vertical-align: middle;
        }
      }
      .footnote-text {
        margin-top: 16px;
        font-size: 12px;
        font-weight: 450;
      }
    }
    // Have added the margin in negative as for responsive design in product tab we have given product-tabs as -32px to match this
    .sticky-submit-btn {
      position: sticky;
      bottom: 0;
      padding: 16px 32px;
      background: #fff;
      z-index: 1000;
      max-width: 768px;
      margin: 0px -32px;
    }
    .title-section {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }
  .thin-label {
    font-size: 16px;
    color: @text-color;
    font-weight: 400;
    margin-bottom: 4px;
    word-break: break-word;
  }
  .bold-label {
    font-size: 28px;
    color: @field-color;
    font-weight: 700;
    word-break: break-word;
  }
  .content-font-size {
    font-size: 16px;
    font-weight: 450;
  }
  .content-page {
    p,
    li {
      margin: 0;
      padding: 0;
    }
  }
  .thank-you-text {
    font-size: 12px;
    color: @text-color;
  }
}

.project-list {
  .ant-form-item-label > label {
    flex-direction: row;
  }
}

.dimension-wrapper {
  position: relative;
  .measurement-tips-link {
    text-decoration: underline;
    margin-top: 36px;
    color: @text-primary;
    font-weight: 450;
    cursor: pointer;
  }
  .cta-button {
    margin-top: 36px;
  }

  .ant-form-item {
    &.has-description {
      margin-bottom: 8px;
    }
  }

  .stairs-select {
    .ant-select-selection-item {
      display: flex;
      justify-content: center;
    }

    .ant-select-selection-placeholder {
      display: flex;
      justify-content: center;
    }
    &.ant-select-single.ant-select-show-arrow
      .ant-select-selection-placeholder {
      padding-right: 0px;
    }
  }

  .input-description {
    margin: 8px 0px;
    color: @text-color;
    font-weight: 450;
    font-size: 12px;
  }
  .stairs-image-section {
    text-align: center;
    width: 100%;
    height: 152px;
    margin: 50px 0;

    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .dimension-read-only.number-component.ant-input-affix-wrapper-readonly {
    background-color: whitesmoke;
    cursor: not-allowed;
  }
  .dimension-read-only.number-component.ant-input-affix-wrapper-readonly
    > input.ant-input {
    cursor: not-allowed;
    background-color: whitesmoke;
    border: 1px solid whitesmoke;
  }

  .dimension-read-only {
    .ant-input-suffix {
      display: none;
    }
  }

  .dimension-decimal-input {
    &.price-component.input-component.ant-input-affix-wrapper {
      text-align: center;
      font-size: 14px;
    }
  }
  // hide arrows for total no. of steps
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .readonly-dimension-decimal-input {
    &.price-component.input-component.ant-input-affix-wrapper {
      text-align: center;
      font-size: 14px;
      background-color: whitesmoke;
      border: 1px solid whitesmoke;
      cursor: not-allowed;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  .measurement-span {
    padding: 12px 6px;
    font-size: 14px;
    font-weight: bold;
  }
  .text-center {
    text-align: center;
  }

  .map-entry-section {
    margin-bottom: 30px;
  }

  // map entry section
  .parent-div {
    position: relative;
  }
}

.measurement-tips-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-modal-body {
    padding: 30px;
    .measurement-tip-wrapper {
      .title {
        color: @text-primary;
        text-align: center;
        font-size: 30px;
      }
      .tips-content {
        margin-top: 36px;
      }

      .cta-button {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}

.highlight-your-area-modal {
  .ant-modal.modal .ant-modal-body {
    max-height: inherit;
    padding: 0px;
  }

  .main-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    overflow-y: auto;
  }

  .back-btn {
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin-bottom: 5px;
    :nth-child(2) {
      max-width: 700px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .anticon svg {
      vertical-align: middle;
    }
  }
  .cta-button {
    margin-top: 36px;
  }

  .common-map {
    width: 100%;
    min-height: 300px;
    flex: 1;
  }

  .center {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top-section {
    width: 100%;
    top: 0px;
    padding: 0px 20px;
  }

  .bottom-section {
    width: 100%;
    bottom: 0px;
    padding: 0px 20px;
  }
  .area-show {
    color: @white-color;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.18);

    .sub-title-text {
      color: @white-color;
      font-size: 16px;
      margin: 8px 0px 6px 0px;
      font-weight: 700;
    }
    .hint {
      margin: 8px 0px;
    }
    .description {
      word-break: break-all;
      text-align: center;
      p {
        margin-bottom: 8px;
      }
    }
    .mt-20 {
      margin-top: 20px;
    }

    .action-buttons {
      width: 45%;
    }

    .area-label {
      font-size: 12px;
      color: @white-color;
      font-weight: 500;
      margin-top: 8px;
    }
    .area-value {
      font-size: 21px;
      margin-bottom: 5px;
      font-weight: 500;
    }
    .area-result {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .dimension-logo-section {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
    }
    .measurement-tip-link {
      text-decoration: underline;
      margin: 10px 0px;
      font-weight: 450;
      cursor: pointer;
    }
  }

  .ml-4 {
    margin-left: 4px;
  }
}
