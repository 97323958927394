.add-on-container {
  .title-divider.ant-divider-horizontal {
    margin: 15px 0;
  }

  .title-text {
    font-weight: bold;
    font-size: 16px;
  }

  .product-title {
    font-weight: 500;
  }

  .skip-add-on-btn {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    &.ant-btn {
      font-size: 16px;
    }
    &.ant-btn > .anticon + span {
      margin-left: 0px;
      margin-right: 8px;
    }
    &.ant-btn > .anticon {
      line-height: 0;
    }
  }

  .skip-add-on-btn.ant-btn:not(:disabled):focus-visible {
    font-size: 17px;
    font-weight: bold;
    text-decoration: underline;
  }
  .arrow {
    font-size: 17px;
    font-weight: bold;
  }
}
