@import '../../styles/variables.less';

.mt-15 {
  margin-top: 15px;
}

.location-title {
  color: @field-color;
  font-weight: 500;
  margin-bottom: 15px;
  word-break: break-word;
}
.location-description {
  padding: 0 10px;
  font-size: 12px;
  color: @secondary-field-color;
  margin-bottom: 15px;
  word-break: break-word;
  .underline-text {
    text-decoration: underline;
    cursor: pointer;
    word-break: break-word;
  }
}
