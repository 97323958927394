@import '../../styles/variables.less';

.ant-select.select-width {
  font-size: 14px;
  min-width: 180px;
  font-weight: 500;
  color: @field-color;
  text-align: center;
  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 44px;
    background: @field-background-color;
    border: none;
    border-radius: 10px;
  }
  .ant-select-selection-placeholder {
    color: @field-placeholder;
  }

  .ant-select-item-option-state {
    position: absolute;
    right: 10px;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 500;
    color: @primary-color;
    position: relative;
    .dropdown-option {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .option-title {
        font-size: 16px;
        font-weight: 500;
        word-break: break-word;
        color: @primary-color;
      }
      .option-description {
        color: @primary-color;
        font-size: 14px;
        font-weight: 500;
        white-space: normal;
        padding: 0 20px;
        word-break: break-word;
      }
    }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    color: @primary-color;
    .dropdown-option {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .option-title {
        font-size: 16px;
        font-weight: 500;
        word-break: break-word;
        color: @primary-color;
      }
      .option-description {
        color: @primary-color;
        font-size: 14px;
        font-weight: 500;
        white-space: normal;
        padding: 0 20px;
        word-break: break-word;
      }
    }
  }
  .ant-select-item {
    font-size: 12px;
    color: @field-color;
  }
  .ant-select-dropdown {
    border-radius: 6px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  }
  .dropdown-option {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .option-title {
      font-size: 16px;
      font-weight: 500;
      word-break: break-word;
    }
    .option-description {
      color: @field-placeholder;
      font-size: 14px;
      font-weight: 500;
      white-space: normal;
      padding: 0 20px;
      word-break: break-word;
    }
  }
}

.ant-select.select-width.ant-select-disabled {
  &:not(.ant-select-customize-input) .ant-select-selector {
    background: @field-background-color;
  }
}
.ant-select.select-width.ant-select-multiple {
  .ant-select-selector {
    padding: 0 8px 0;
  }
  .ant-select-selection-item:first-child {
    margin-left: 0px;
  }
  .ant-select-selection-item {
    margin: 0 4px 0;
    color: @text-color;
    background: @tag-background;
    border-color: @tag-border;
    height: 24px;
    padding: 4px 8px;
    font-weight: 400;
    border-radius: 4px;
    .ant-select-selection-item-remove {
      color: @text-color;
      font-size: 12px;
      vertical-align: unset;
      .anticon {
        color: @text-color;
        vertical-align: inherit;
      }
    }
    .ant-select-selection-item-content {
      overflow: visible;
      display: flex;
      margin-right: 2px;
      line-height: 16px;
    }
  }
}

.ant-select.select-width.ant-select-single:not(.ant-select-customize-input) {
  .ant-select-selector {
    padding: 4px 8px;
  }
  .ant-select-selection-search {
    left: 8px;
    top: 5px;
  }
}
.ant-select.select-width {
  &.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
    .ant-select-selector {
    background: @field-background-color;
    border-radius: 6px;
    border: 1px solid @field-error;
  }
}
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open
  .ant-select-selector,
.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused
  .ant-select-selector {
  border: 1px solid @field-error;
}

.ant-form-item-has-error
  .ant-select.select-width.ant-select-disabled:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid @field-error;
}
