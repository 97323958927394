@import '../../styles/variables.less';

.postal-code-page {
  .map-section {
    margin-top: 60px;
  }
  .zip-section {
    margin-top: 60px;
    .marker-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      border-radius: 6px;
    }
    .marker-btn:hover {
      background-color: @primary-color;
      img {
        background-color: @primary-color;
      }
    }
  }
  .footnote-section {
    margin-top: 24px;
    .footnote-text {
      display: flex;
      color: @secondary-field-color;
      font-weight: 400;
      text-align: center;
      justify-content: center;
      word-break: break-word;
    }
  }
}
