.ant-modal {
  &.modal {
    .ant-modal-title {
      color: #2c2f37;
      font-weight: 600;
    }
    .ant-modal-close-x {
      .anticon-close {
        color: #72788f;
      }
    }
    .ant-modal-header {
      padding: 16px;
    }
    .ant-modal-body {
      max-height: 500px;
      overflow-y: auto;
      padding: 16px;
    }
  }
}
