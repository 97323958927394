@import '../../styles/variables.less';
.my-cart-wrapper {
  margin-top: 37px;
  .add-on-product {
    padding-left: 15px;
  }

  .delete-quote-btn.ant-btn:focus-within {
    -webkit-box-shadow: 0.5px 2px 32px -6.5px #fe0000;
    -moz-box-shadow: 0.5px 2px 32px -6.5px #fe0000;
    box-shadow: 0.5px 2px 32px -6.5px #fe0000;
  }
  .title-wrapper {
    vertical-align: middle;
    font-size: 22px;
    margin-left: auto;
    margin-right: auto;
  }

  .package-title-quote-variant {
    width: 80%;
    text-align: left;
  }
  .package-title-quote-variant.bold-label {
    font-size: 20px;
  }
  .add-on-title-quote-variant.bold-label {
    font-size: 16px;
  }
  .sub-title {
    color: #14181c;
    margin-top: 8px;
    font-size: 14px;
  }
  .ml-16 {
    margin-left: 26px;
  }

  .panel-content {
    color: @text-primary;
    font-size: 14px;
    font-weight: 450;
  }
  .change-quote-section {
    margin: 24px 0 22px 0;
    .back-link {
      .arrow {
        margin-right: 9px;
      }
      span {
        color: @primary-color;
        font-weight: 450;
        font-size: 16px;
        word-break: break-all;
        text-decoration: underline;
      }
      :hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
    .change-my-quote-btn {
      color: @primary-color;
      font-weight: 450;
      &.ant-btn {
        padding: 7.9px 2px; // 7.9px is default padding for antd button. we are just changing the left-right padding
      }
      &.ant-btn > .anticon + span {
        font-size: 16px;
        word-break: break-all;
        text-decoration: underline;
      }
    }
    .change-my-quote-btn.ant-btn:not(:disabled):focus-visible {
      &.ant-btn > .anticon + span {
        font-size: 17px;
        font-weight: bold;
      }
    }

    .amount-section {
      color: #14181c;
      font-weight: 700;
      font-size: 18px;
    }
  }

  .cart-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    margin-right: 4px;
  }

  .cart-divider.ant-divider {
    border-bottom: 1px solid @border-color;
  }

  .cart-detail-collapse.ant-collapse-borderless {
    background-color: inherit;
  }
  .cart-detail-collapse.ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: unset;
  }
  .cart-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 12px 0px;

    .ant-collapse-header-text {
      font-size: 16px;
      color: #2c2f37;
      font-weight: 500;
      display: flex;
    }
  }

  .total-amount-section {
    line-height: 27px;
    color: #5f6786;

    .label {
      color: #5f6786;
      font-size: 14px;
    }
    .amount {
      color: #14181c;
      font-weight: 700;
      font-size: 22px;
    }
  }

  .add-new-project.ant-btn {
    color: @primary-color;
    margin-top: 37px;
    border: 1px solid @primary-color;
    border-radius: 10px;
    font-weight: 500;
    font-size: 15px;
    height: 44px;
    background: @white-color;
    width: 100%;
    overflow: hidden;
    span {
      font-weight: 500;
      font-size: 15px;
    }
  }

  .add-new-project.ant-btn:focus-within {
    -webkit-box-shadow: 0.5px 2px 32px -6.5px @primary-color;
    -moz-box-shadow: 0.5px 2px 32px -6.5px @primary-color;
    box-shadow: 0.5px 2px 32px -6.5px @primary-color;
  }

  .description-text {
    margin-top: 37px;
    font-size: 12px;
    color: #72788f;
  }
  .empty-project {
    .ant-empty-description {
      font-weight: 600;
    }
  }
}

.delete-button-tooltip {
  .ant-popover-inner-content {
    width: 280px;
  }
}
