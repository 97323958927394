@import '../../styles/variables.less';

.scheduled-appointment-page {
  .single-project {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .single-item {
      font-size: 14px;
      font-weight: 450;
      line-height: 21px;
      color: #14181c;
      text-align: center;
    }
  }
  .total {
    display: flex;
    justify-content: flex-end;
    font-size: 21px;
    font-weight: 700;
    line-height: 32px;
    color: #14181c;
  }
  .title-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    .bold-label {
      font-size: 28px;
      color: @field-color;
      font-weight: 600;
      margin-bottom: 12px;
      word-break: break-word;
    }
  }
  .location-section {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
    .location-title {
      color: @field-color;
      font-weight: 500;
      margin-bottom: 15px;
      word-break: break-word;
    }
    .location-description {
      padding: 0 10px;
      font-size: 12px;
      color: @secondary-field-color;
      margin-bottom: 15px;
      word-break: break-word;
      .underline-text {
        text-decoration: underline;
        cursor: pointer;
        word-break: break-word;
      }
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
    .marker-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      border-radius: 6px;
    }
    .ant-select.select-width {
      min-width: unset;
    }
  }
  .time-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    .time-title {
      margin-top: 30px;
      color: @field-color;
      font-weight: 500;
      word-break: break-word;
    }
    .holiday-title {
      color: @field-color;
      font-weight: 500;
      word-break: break-word;
    }
    .holiday-bold {
      font-weight: 600;
    }
    .checkbox-margin {
      margin: 16px;
    }
    .week-panel {
      &.ant-collapse {
        .ant-collapse-item {
          .ant-collapse-header {
            .ant-collapse-header-text {
              flex: none;
            }
          }
        }
      }
      .inside-time-checkbox {
        width: 100%;
        text-align: left;
        margin: 8px;
        margin-left: 24px;
      }

      /* For keyboard navigation, you might want to make it focus-visible */
      .ant-collapse-header:focus-visible .ant-collapse-arrow {
        border: 2px solid @primary-color; /* Different border color */
        box-shadow: 0 0 5px @primary-color; /* Different shadow */
      }
    }
    .calendar-picker {
      border-radius: 0;
      background: @field-background-color;
      .ant-picker-calendar-header {
        padding: 8px 0;
        justify-content: center;
      }
      .ant-picker-panel {
        border-radius: 0;
        background: @field-background-color;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        background-color: @field-background-color;
      }
      .ant-radio-button-wrapper {
        border: none;
        background-color: @field-background-color;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        display: none;
      }
      .ant-picker-cell-disabled::before {
        background: unset;
      }
      .events {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      .ant-picker-cell-today {
        .single-date {
          border: 1px solid @primary-color;
          border-radius: 8px;
        }
      }
      .single-date {
        display: inline-block;
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 2px;
      }
      .holiday {
        padding: 0 4px;
        color: #f5222d;
        background-color: #fff1f0;
        border-radius: 6px;
      }
      .selected-date {
        padding: 0 4px;
        color: @white-color;
        background-color: @primary-color;
        border-radius: 6px;
      }
    }
    .time-slots {
      margin-bottom: 15px;
      .ant-radio-button-wrapper {
        height: 30px;
        border-radius: 0;
        margin-top: 5px;
        font-size: 12px;
        background-color: @field-background-color;
        transition: none;
      }
      .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
        background-color: @field-background-color;
        color: @primary-color;
        transition: none;
      }
      .ant-radio-button-wrapper-checked {
        border-right: 1px solid @primary-color;
        transition: none;
      }
      .ant-radio-button-wrapper-checked:hover {
        border: 1px solid @primary-color;
        transition: none;
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        background-color: @primary-color;
      }
      .ant-radio-button-wrapper > span:last-child {
        margin-bottom: 2px;
      }
      &.ant-btn.ant-btn-primary.time-slots {
        color: @white-color;
        background-color: @primary-color;
      }
    }
    #expanded-date-picker {
      .ant-picker {
        padding: unset;
        height: 0px;
        visibility: hidden;
      }
      .ant-picker-dropdown {
        z-index: unset;
        position: relative;
        top: unset !important;
        left: unset !important;
        .ant-picker-panel-container {
          box-shadow: none;
        }
      }
      > div:last-child {
        position: relative !important;
      }
    }
  }
  .appointment-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    .appointment-title {
      color: @field-color;
      font-weight: 500;
      margin-bottom: 15px;
      word-break: break-word;
    }
    .appointment-card {
      border: unset;
    }
    .appointment-description {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
      word-break: break-word;
    }
    .half-width {
      width: 50%;
    }
    .divider {
      margin: 15px 0px;
      border-top: 1px solid @border-color;
    }
    .reserve-phone-section {
      color: @field-color;
      font-weight: 500;
      .top-margin {
        margin-top: 25px;
      }
      .icon-margin {
        margin-right: 15px;
      }
    }
    .tab-description {
      font-size: 13px;
      margin-bottom: 16px;
      font-weight: 400;
      margin-bottom: 15px;
      color: #808080;
      word-break: break-word;
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
  }
  .down-arrow {
    color: @border-color-secondary;
    margin: 10px 0 25px;
    font-size: 20px;
  }
  .edit-btn > span {
    font-weight: 600;
    text-decoration: underline;
  }
  .edit-btn:focus-within {
    &.ant-btn > span {
      font-size: 17px;
      font-weight: bold;
    }
  }
  .info-card {
    cursor: pointer;
    font-weight: 500;
    color: @field-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid @border-color-secondary;
    margin-bottom: 15px;
    .info-title {
      margin-top: 8px;
      word-break: break-word;
    }
    .anticon {
      font-size: 20px;
    }
  }

  .info-card-box-shadow {
    -webkit-box-shadow: 0.5px 2px 32px -6.5px @primary-color;
    -moz-box-shadow: 0.5px 2px 32px -6.5px @primary-color;
    box-shadow: 0.5px 2px 32px -6.5px @primary-color;
  }
  .click-enabled {
    cursor: pointer;
    color: @primary-color;
    border: 1px solid @primary-color;
  }
  .click-disabled {
    cursor: not-allowed;
  }
  .change-step {
    cursor: pointer;
    color: @primary-color;
    text-decoration: underline;
    margin-top: 15px;
  }
  .step-description {
    padding: 0 10px;
    font-size: 12px;
    color: @secondary-field-color;
    margin-top: 15px;
    word-break: break-word;
  }
  .mt-0 {
    margin-top: 0;
  }
}

.overlay-text.ant-popover {
  max-width: @portal-width - 2 * @padding-horizontal;
  font-size: 12px;
  color: @field-color;
  text-align: center;
  word-break: break-word;
}
