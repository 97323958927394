@import '../../styles/variables.less';
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.number-component {
  .ant-input {
    color: @field-color;
    font-weight: 500;
    background-color: @field-background-color;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid @border-color;
    text-align: center;
  }

  .ant-input::placeholder {
    color: @field-placeholder;
  }
  .ant-input:placeholder-shown {
    background-color: @field-background-color;
  }
}

.ant-form-item-has-error {
  .number-component.ant-input-affix-wrapper,
  .number-component.ant-input-affix-wrapper :hover {
    background-color: @field-background-color;
  }
  .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    background-color: @field-background-color;
  }
  .number-component.ant-input-affix-wrapper-focused {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
  .number-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid @field-error;
    background-color: @field-background-color;
  }
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).number-component.ant-input-affix-wrapper {
  background-color: @field-background-color;
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).number-component.ant-input-affix-wrapper
  > input.ant-input {
  background-color: @field-background-color;
}

.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).number-component.ant-input-affix-wrapper:hover {
  background-color: @field-background-color;
}

.number-component.ant-input-affix-wrapper {
  border: 1px solid @border-color;
  background-color: @field-background-color;
  height: 44px;
  border-radius: 10px;
}

.number-component.ant-input-affix-wrapper > input.ant-input {
  background-color: @field-background-color;
}

.number-component.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: @field-background-color;
  z-index: 1;
}
