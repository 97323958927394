@import '../../styles/variables.less';

.contact-me-page {
  .ant-form-item-explain.ant-form-item-explain-error {
    margin-top: 3px;
    color: @field-error;
    font-size: 10px;
  }
  .title-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .thin-title {
      color: @secondary-field-color;
      font-size: 18px;
      word-break: break-word;
    }
    .bold-title {
      color: @field-color;
      font-size: 28px;
      font-weight: 600;
      word-break: break-word;
    }
  }

  .count-down-section {
    text-align: center;
    margin-top: 27px;
    .time {
      color: @field-color;
      .ant-statistic-content {
        font-weight: 600;
        font-size: 18px;
        color: @black-color;
      }
    }
    .description {
      font-size: 14px;
      color: @secondary-field-color;
      word-break: break-word;
    }
  }
  .no-product-text {
    text-align: center;
    font-size: 14px;
    color: @secondary-field-color;
    word-break: break-word;
  }

  .form-section {
    margin-top: 27px;
    padding: 0 10px;
    .comment-title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: @field-color;
      font-weight: 500;
      font-size: 14px;
    }
    .comment-input {
      margin-top: 15px;
    }

    .ant-checkbox + span {
      align-self: center;
      font-weight: 500;
      font-size: 14px;
      color: @primary-color;
    }
    .ant-checkbox-group {
      padding: 0 10px;
    }
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: unset;
      margin-top: 25px;
    }
    .confirm-email.common-checkbox {
      .ant-checkbox-inner::after {
        left: 33%;
      }
    }
  }

  .contact-me-footer {
    margin-top: 24px;
    .back-link {
      font-size: 13px;
      color: @secondary-field-color;
      &.ant-btn > span {
        text-decoration: underline;
      }
    }
  }
}

.contact-page-wrapper {
  margin-top: 37px;

  .package-title-quote-variant {
    width: 80%;
    text-align: left;
    margin-bottom: 10px;

    .add-on-text {
      margin-right: 5px;
    }
  }
  .package-title-quote-variant.bold-label {
    font-size: 20px;
  }
  .add-on-title-quote-variant.bold-label {
    font-size: 16px;
  }
  .sub-title {
    color: #14181c;
    margin-top: 8px;
    font-size: 14px;
  }

  .contact-divider.ant-divider {
    border-bottom: 1px solid @border-color;
  }
  .contact-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 12px 0px;

    .ant-collapse-header-text {
      font-size: 16px;
      color: #2c2f37;
      font-weight: 500;
    }
  }

  .contact-detail-collapse.ant-collapse-borderless {
    background-color: inherit;
  }

  .change-my-quote-btn {
    color: @primary-color;
    font-weight: 450;
    margin-top: 5px;
    &.ant-btn {
      padding: 7.9px 2px; // 7.9px is default padding for antd button. we are just changing the left-right padding
    }
    &.ant-btn > .anticon + span {
      font-size: 16px;
      word-break: break-all;
      text-decoration: underline;
    }
  }
  .change-my-quote-btn.ant-btn:not(:disabled):focus-visible {
    font-size: 20px;
    font-weight: bold;
  }

  .amount-section {
    color: #14181c;
    font-weight: 700;
    font-size: 18px;
  }

  .form-section {
    text-align: center;
    .optional-comment {
      color: @black-color;
      font-weight: 500;
      font-size: 14px;
    }
    .mt-30 {
      margin-top: 30px;
    }
    .mt-20 {
      margin-top: 20px;
    }
    .mt-15 {
      margin-top: 15px;
    }
    .mt-3 {
      margin-top: 3px;
    }
  }

  .contact-detail-collapse.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 12px 0px;

    .ant-collapse-header-text {
      font-size: 16px;
      color: #2c2f37;
      font-weight: 500;
      display: flex;
    }
  }

  .contact-detail-collapse.ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: unset;
  }
}
