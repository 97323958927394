body {
  max-width: @portal-width;
  font-family: @font-family;
  margin-right: auto;
  margin-left: auto;
  border-radius: 8px;
  border: none;
  @media screen and (max-width: 768px) {
    max-width: unset;
    width: 100%;
  }
}
#root {
  // commented because of #DRF-9864
  // height: 100vh;
  font-family: @font-family;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: normal;
  .gx-sidebar-content {
    flex: 1.5;
    overflow-y: auto;
    overflow-x: hidden;
    & > .ant-menu {
      & > .ant-menu-item {
        margin: 12px 0;

        & > .menu-icons {
          font-size: 20px;
        }

        & > span {
          font-size: 18px;
        }
      }
      & > .ant-menu-submenu {
        margin: 12px 0;
        padding: 0;
        .ant-menu-submenu-title {
          display: flex;
          align-items: center;
          width: 100%;
          & > .anticon {
            font-size: 20px;
          }
          & > span {
            font-size: 18px;
          }
        }
      }
    }
  }
  .gx-linebar {
    & > .ant-menu {
      & > .ant-menu-item {
        margin: 12px 0;

        & > .menu-icons {
          font-size: 20px;
        }

        & > span {
          font-size: 18px;
        }
      }
      & > .ant-menu-submenu {
        margin: 12px 0;
        padding: 0;
        .ant-menu-submenu-title {
          display: flex;
          align-items: center;
          width: 100%;
          & > .anticon {
            font-size: 20px;
          }
          & > span {
            font-size: 18px;
          }
        }
      }
    }
    & > .gx-icon-btn {
      margin: 12px 0;
      padding-left: 40px;
      & > span {
        & > span {
          margin-right: 20px;
          transition: right 500ms;
        }
      }
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: #efefef;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

//CUSTOM STYLE CLASSES START

//margin & padding style
.m-0 {
  margin: 0;
}

.mt-27 {
  margin-top: 27px;
}

.p-0 {
  padding: 0;
}
//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.flex-flow-col-wrap {
  flex-flow: column wrap;
}
// flex-box related style

//width classes
.width-percent-20 {
  width: 20%;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}

.width-percent-25 {
  width: 25%;
}

.fill-width {
  width: 100%;
}
//width classes

//cursor
.pointer {
  cursor: pointer;
}
//cursor

//border style
.b-0 {
  border: none;
}
//border style
.recaptcha-wrapper {
  display: none;
}

.full-height-card {
  background-color: @white-color;
  border: none;
}

.card-body-padding {
  .ant-card-body:first-child {
    padding: 0;
  }
}

// common map class
.common-map-cpq {
  border-radius: 50%;
  width: 100%;
  max-width: 375px;
  margin: auto;
}

// common class for checkbox
.common-checkbox {
  font-size: 14px;
  font-weight: 500;
  .ant-checkbox + span {
    color: @field-color;
    padding: 0px 12px;
    align-self: center;
    word-break: break-word;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    text-align: left;
  }
  .ant-checkbox-inner::after {
    left: 35%;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
  .ant-checkbox-inner {
    background-color: @field-background-color;
    border: none;
    border-radius: 4px;
    height: 24px;
    width: 24px;
  }

  .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
    outline: 2px solid @primary-color;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
  }
}

// common for all pages

// logo
.logo-section {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  img {
    width: 300px;
    max-height: 150px;
    object-fit: contain;
  }
  .second-logo {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  .logo-text {
    color: @text-color;
    font-weight: 450;
    display: flex;
    align-items: center;
  }
}

// contact
.contact-section {
  margin-top: 16px;
  .contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .details-text {
      cursor: pointer;
      font-size: 12px;
      color: @secondary-field-color;
      line-height: 18px;
      a {
        color: unset;
      }
    }
    .underline-text {
      line-height: 16px;
      margin-top: 20px;
      text-decoration: underline;
      .anticon {
        margin-right: 8px;
      }
      a {
        color: unset;
      }
    }
  }
  .email-me-section {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    .email-me-btn {
      font-weight: 600;
      height: 24px;
      padding: 0;
      text-decoration: underline;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      .anticon {
        margin-left: 10px;
        align-self: end;
      }
    }
    .email-me-btn:hover {
      text-decoration: underline;
    }
  }
}

// button
.common-button {
  width: 100%;
  height: unset;
  min-height: 44px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  box-shadow: 0px 6px 15px fade(@primary-color, 25%);
  white-space: normal;
  word-break: break-word;
}

.common-button:focus-within {
  -webkit-box-shadow: 0.5px 2px 32px -6.5px @primary-color;
  -moz-box-shadow: 0.5px 2px 32px -6.5px @primary-color;
  box-shadow: 0.5px 2px 32px -6.5px @primary-color;
}

// footer
.location-footer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  .location-info {
    color: @primary-color;
    margin-left: 8px;
    font-weight: 600;
    font-size: 14px;
  }
}

// search
.list-search-box {
  font-weight: 400;
  border: none;
  border-radius: 10px;
  height: 44px;
  width: 100%;
  background-color: @field-background-color;
  color: @field-color;
  .ant-input {
    text-align: center;
    font-size: 14px;
    color: @field-color;
    background-color: @field-background-color;
  }
  .ant-input::placeholder {
    color: @field-placeholder;
  }
  .anticon-search {
    margin-left: 11px;
    color: #dadada;
  }
}

.list-search-box.ant-input-affix-wrapper-sm {
  padding: 2px 11px;
}

.search-checkbox {
  margin-bottom: 24px;
  display: flex;
  height: 44px;
  align-items: center;
}

// line clamp
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// center error
.ant-form-item-explain {
  text-align: center;
}

// primary-color
.primary-color {
  color: @primary-color;
}

// font size
.font-size-12 {
  font-size: 12px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-32 {
  font-size: 32px;
}

// margin

.m-auto {
  margin: auto;
}

.mx-12 {
  margin: 12px 0;
}

.mt-4 {
  margin-top: 4px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-37 {
  margin-top: 37px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-72 {
  margin-top: 72px;
}

.ml-4 {
  margin-left: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-8 {
  margin-right: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

// padding

.pl-8 {
  padding-left: 8px;
}

.font-bold {
  font-weight: 500;
}

.common-range-picker {
  &.ant-picker {
    background-color: @field-background-color;
    border: none;
    height: @field-height;
    .ant-picker-input > input {
      font-weight: 500;
      font-size: 12px;
    }
  }
  td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {
    color: @white-color;
  }
}
.common-range-picker {
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner {
    font-weight: 500;
    font-size: 12px;
  }
}
.common-range-picker-dropdown {
  td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range {
    color: rgba(0, 0, 0, 0.25);
  }
}

.ant-form-item-has-error .common-range-picker.ant-picker:not([disabled]):hover {
  background-color: @field-background-color;
}

.text-center {
  text-align: center;
}

// editor component div class
.editor-render {
  :is(body, div, h1, h2, h3, h4, h5, h6, p) {
    margin: 0;
    padding: 0;
    text-align: left;
  }
  :is(ul, ol, li) {
    margin: 0;
    padding: 0;
    text-align: left;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 1.5em;
    padding-inline-end: 0;
    list-style-position: inside;
    word-break: break-word;
  }

  :is(ul, li) {
    list-style-position: outside;
  }
  a {
    text-decoration: underline;
  }
}

// common class for textarea
.common-textarea {
  background-color: @field-background-color;
  border: none;
  font-weight: 500;
  color: @field-color;
  border-radius: 10px;
  .ant-input {
    background-color: @field-background-color;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    color: @field-color;
    &::placeholder {
      color: @placeholder-color;
      font-weight: 400;
    }
  }
  .ant-input:hover {
    box-shadow: none;
    border: none;
  }
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none;
    border: none;
  }

  &.ant-input-textarea-status-error {
    border-radius: 10px;
    background-color: @field-background-color;
    border: 1px solid @field-error;
  }

  &.ant-form-item-has-error
    :not(.input-component.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.input-component.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.input-component.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.input-component.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border-radius: 10px;
    background-color: @field-background-color;
    border: 1px solid @field-error;
  }
}

// added !important over here as rc-virtual-list package uses inline css to hide scroll bar in select
.pick-list-drop-down {
  .rc-virtual-list-holder {
    overflow-y: auto !important;
    transform: none;
  }
}

// iframe warning css
.warning-container {
  background-color: @white-color;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 800px;
  margin: 1rem;
}

.warning-title {
  color: @error;
  margin: 0 0 1.5rem 0;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.warning-text {
  color: @description-color;
  line-height: 1.6;
  margin: 0;
  font-size: 1rem;
}
