@import '../../styles/variables.less';

.custom-form-item {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  .item-label {
    font-size: 16px;
    color: @field-color;
  }

  .custom-tooltip {
    margin: 0 0 3px 4px;
    .anticon {
      cursor: help;
    }
  }
  .required-mark {
    display: inline-block;
    font-size: 14px;
    margin-left: 4px;
    line-height: 1;
    font-family: SimSun, sans-serif;
  }
}

.cursor-disabled {
  cursor: not-allowed;
}

.pointer-event-none {
  pointer-events: none;
}

.render-upload {
  .ant-upload.ant-upload-select-picture-card {
    background: @white-color;
    width: 138px;
    height: 137px;
    border: 1px dashed @upload-border;
  }
  span.ant-upload:focus-visible {
    outline: 1px solid;
  }
  .ant-upload-list-item-info > span {
    font-size: 7rem;
    color: @primary-color;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 52px;
  }
  .ant-upload-list-picture-card
    .ant-upload-list-item-uploading.ant-upload-list-item {
    background: @white-color;
    border: 1px dashed @upload-border;
  }

  .ant-upload-list-picture-card-container {
    width: 138px;
    height: 180px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    border-radius: 6px;
  }
  div.ant-upload-list-item-thumbnail {
    position: relative;
    top: 15px;
  }

  .ant-upload-list-picture-card
    .ant-upload-list-item-info
    .ant-upload-span.ant-upload-list-item-thumbnail
    .ant-upload-list-item-file
    .anticon-file {
    display: none;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 138px;
    height: 137px;
    border: none;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    right: 0;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info {
    background: @white-color;
  }

  .ant-upload-list-picture-card-container {
    margin-bottom: 0;
  }

  .upload-content {
    display: flex;
    flex-direction: column;
    .upload-title {
      margin-top: 17px;
      font-size: 11px;
      color: @black-color;
    }
    .upload-description {
      margin-top: 3px;
      font-size: 12px;
      color: @upload-description;
    }
    .icon-header {
      font-size: 13px;
      color: @black-color;
    }
  }

  .file-type {
    width: 117px;
    height: 95px;
    margin: 2px 2px;
    background-color: @white-color;
  }
  .gallery-label {
    width: 138px;
    text-align: center;
    margin-top: 10px;
    font-size: 11px;
    color: @black-color;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .render-upload-label {
    width: 138px;
    margin-top: 10px;
    font-size: 11px;
    color: @black-color;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
